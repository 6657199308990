import Dashboard from "../../components/utopie/pages/Dashboard";
import Terms from "../../components/utopie/pages/Terms";
import Fudei from "../../components/tests/Fudei";
import InvitationRegister from "../../components/utopie/miscellaneous/InvitationRegister";
import Marketplace from "../../components/utopie/pages/Marketplace";
import Media from "../../components/utopie/pages/Media";
import NewPlanification from "../../components/tests/NewPlanification";
import PostComponent from "../../components/utopie/miscellaneous/PostComponent";
import ProfileNotifications from "../../components/utopie/pages/ProfileNotifications";
import ProfileMissions from "../../components/utopie/pages/ProfileMissions";
import ProfilePoints from "../../components/utopie/pages/ProfilePoints";
import ProfilePosts from "../../components/utopie/pages/ProfilePosts";
import ProfileTests from "../../components/utopie/pages/ProfileTests";
import ProfileDelete from "../../components/utopie/pages/ProfileDelete";
import AddOrChangePasswordAdmin from "../../components/utopie/miscellaneous/AddOrChangePasswordAdmin";
import Router from "vue-router";
import Vue from "vue";

Vue.use(Router);

export default new Router({
    base: '/utopie/',
    routes: [
        { path: "/", component: Dashboard, name: "home" },
        {
            path: "/terms",
            component: Terms,
            name: "terms",
            meta: { layout: "OnlyText" }
        },
        {
            path: "/profile",
            component: ProfilePosts,
            name: "profile",
            meta: { layout: "profile" }
        },
        {
            path: "/marketplace",
            component: Marketplace,
            name: "marketplace",
            meta: { layout: "marketplace" }

        },
        {
            path: "/profile/notifications",
            component: ProfileNotifications,
            name: "profile_notifications",
            meta: { layout: "profile" }
        },
        {
            path: "/profile/missions",
            component: ProfileMissions,
            name: "profile_missions",
            meta: { layout: "profile" }
        },
        {
            path: "/profile/tests",
            component: ProfileTests,
            name: "profile_tests",
            meta: { layout: "profile" }
        },
        {
            path: "/profile/points",
            component: ProfilePoints,
            name: "profile_points",
            meta: { layout: "profile" }
        },
        {
            path: "/profile/delete",
            component: ProfileDelete,
            name: "profile_delete",
            meta: { layout: "profile" }
        },
        { path: "/post/:id", component: PostComponent, name: "post" },
        { path: "/design", name: "design", meta: { layout: "design" } },
        {
            path: "/new_planification",
            component: NewPlanification,
            name: "new_planification",
            meta: { layout: "no_side_profile" }
        },
        {
            path: "/invitation/:uid",
            component: InvitationRegister,
            name: "invitation_register",
            meta: { layout: "no_side_profile" }
        },
        {
            path: "/agregar-cambiar-usuarios",
            name: "agregar-cambiar-usuarios",
            meta: { layout: "fullscreen" },
            component: AddOrChangePasswordAdmin,
        },
        {
            path: "/media",
            component: Media,
            name: "media",
            meta: { layout: "no_side_profile" }
        },
        {
            path: "/fudei/:id",
            name: "fudei",
            component: Fudei,
            meta: { layout: "fullscreen" }
        },
        { path: "*", component: Dashboard },
    ]
});

<template>
  <b-container class="no-padding">
    <b-modal hide-footer size="xl" ref="eureka-modal">
      <eureka-table />
    </b-modal>
    <!--<PublishComponent v-on:publish-post="publish_post" :tags="custom_tags" />-->
    <redirect-options @open-test-modal="$emit('open-test-modal')" 
      @create-school="$emit('create-school')" @create-school-docs="$emit('create-school-docs')"
      @open-eureka="openEureka"
    />
    <Publish v-on:publish-post="publish_post" :tags="custom_tags" @loadingFunc="setLoadingFunc" v-if="$store.state.login.school"/>
    <hr />
    <div v-if="false" class="w-100" style="height:38px;">
      <div class="float-left">
        <h4>Filtros rápidos</h4>
      </div>
      <div class="text-center float-right">
        <!--<div class="btn no-hover-info p-1" v-if="searching_by.subtags.length>0">
          Filtrado por:
          <b-badge
            v-for="(subtag, index) in this.searching_by.subtags"
            :key="'subtag-'+index"
            variant="info"
            class="cursor-pointer"
            @click="remove_subtag_by_index(index)"
          >{{subtag}} ×</b-badge>
        </div>-->
      </div>
    </div>
    <div class="p-0 filters radius-box-8 ml-1" v-if="$store.state.login.token">
      <b-dropdown size="sm" class="normal-dropdown" variant="outline">
        <template v-slot:button-content class="m-0">
          Filtro por qué es
        </template>
        <b-dd-form>
          <b-form-checkbox
            size="sm"
            v-for="(subtag, index) in
              (custom_tags.filter(tag => tag.text.includes('Qué es esto'))[0] !== undefined?
                custom_tags.filter(tag => tag.text.includes('Qué es esto'))[0].subtags : [])"
            v-bind:key="index+'-subtag'"
            v-model="whatIsArray"
            :value="subtag.text">{{subtag.text}}</b-form-checkbox>
        </b-dd-form>
      </b-dropdown>

      <b-dropdown size="sm" class="normal-dropdown" variant="outline">
        <template v-slot:button-content>
          Filtro por Diagnóstico
        </template>
        <b-dd-form>
          <b-form-checkbox
            size="sm"
            v-for="(subtag, index) in
              (custom_tags.filter(tag => tag.text.includes('diagnóstico'))[0] !== undefined?
                custom_tags.filter(tag => tag.text.includes('diagnóstico'))[0].subtags : [])"
            v-bind:key="index+'-subtag'"
            v-model="diagArray"
            :value="subtag.text">{{subtag.text}}</b-form-checkbox>
        </b-dd-form>
      </b-dropdown>

      <div class="d-none d-xl-inline d-lg-inline">
        <b-dropdown size="sm" class="normal-dropdown" variant="outline">
          <template v-slot:button-content>
            Filtro por Curso
          </template>
          <b-dd-form>
            <b-form-checkbox
              size="sm"
              v-for="(subtag, index) in
                (custom_tags.filter(tag => tag.text.includes('curso'))[0] !== undefined?
                  custom_tags.filter(tag => tag.text.includes('curso'))[0].subtags:[])"
              v-bind:key="index+'-subtag'"
              v-model="gradeArray"
              :value="subtag.text">{{subtag.text}}</b-form-checkbox>
          </b-dd-form>
        </b-dropdown>

        <b-dropdown size="sm" class="normal-dropdown" variant="outline">
          <template v-slot:button-content>
            Filtro por Profesión
          </template>
          <b-dd-form>
            <b-form-checkbox
              size="sm"
              v-for="(subtag, index) in
                (custom_tags.filter(tag => tag.text.includes('Profesión'))[0] !== undefined?
                  custom_tags.filter(tag => tag.text.includes('Profesión'))[0].subtags:[])"
              v-bind:key="index+'-subtag'"
              v-model="professionArray"
              :value="subtag.text">{{subtag.text}}</b-form-checkbox>
          </b-dd-form>
        </b-dropdown>

        <b-dropdown size="sm" class="normal-dropdown" variant="outline">
          <template v-slot:button-content>
            Filtro por Habilidad
          </template>
          <b-dd-form>
            <b-form-checkbox
              size="sm"
              v-for="(subtag, index) in
                (custom_tags.filter(tag => tag.text.includes('habilidad'))[0] !== undefined?
                  custom_tags.filter(tag => tag.text.includes('habilidad'))[0].subtags:[])"
              v-bind:key="index+'-subtag'"
              v-model="habilityArray"
              :value="subtag.text">{{subtag.text}}</b-form-checkbox>
          </b-dd-form>
        </b-dropdown>
      </div>
      <div class="d-inline d-lg-none d-xl-none">
        <b-button v-b-toggle.collapse-filter size="sm" pill variant="unknown">Ver más...</b-button>
      </div>
      <b-collapse id="collapse-filter" class="m-0 p-0">
        <b-card fluid>
          <b-dropdown size="sm" class="normal-dropdown" variant="outline">
            <template v-slot:button-content>
              Filtro por Curso
            </template>
            <b-dd-form>
              <b-form-checkbox
                size="sm"
                v-for="(subtag, index) in
                  (custom_tags.filter(tag => tag.text.includes('curso'))[0] !== undefined?
                    custom_tags.filter(tag => tag.text.includes('curso'))[0].subtags:[])"
                v-bind:key="index+'-subtag'"
                v-model="gradeArray"
                :value="subtag.text">{{subtag.text}}</b-form-checkbox>
            </b-dd-form>
          </b-dropdown>

          <b-dropdown size="sm" class="normal-dropdown" variant="outline">
            <template v-slot:button-content>
              Filtro por Profesión
            </template>
            <b-dd-form>
              <b-form-checkbox
                size="sm"
                v-for="(subtag, index) in
                  (custom_tags.filter(tag => tag.text.includes('Profesión'))[0] !== undefined?
                    custom_tags.filter(tag => tag.text.includes('Profesión'))[0].subtags:[])"
                v-bind:key="index+'-subtag'"
                v-model="professionArray"
                :value="subtag.text">{{subtag.text}}</b-form-checkbox>
            </b-dd-form>
          </b-dropdown>

          <b-dropdown size="sm" class="normal-dropdown" variant="outline">
            <template v-slot:button-content>
              Filtro por Habilidad
            </template>
            <b-dd-form>
              <b-form-checkbox
                size="sm"
                v-for="(subtag, index) in
                  (custom_tags.filter(tag => tag.text.includes('habilidad'))[0] !== undefined?
                    custom_tags.filter(tag => tag.text.includes('habilidad'))[0].subtags:[])"
                v-bind:key="index+'-subtag'"
                v-model="habilityArray"
                :value="subtag.text">{{subtag.text}}</b-form-checkbox>
            </b-dd-form>
          </b-dropdown>
        </b-card>
      </b-collapse>

      <!-- <b-dropdown size="sm" class="normal-dropdown" variant="outline">
        <template v-slot:button-content>
          Filtro por Tipo de material
        </template>
        <b-dd-form>
          <b-form-checkbox
            v-for="(subtag, index) in
              (custom_tags.filter(tag => tag.text.includes('material'))[0] !== undefined?
                custom_tags.filter(tag => tag.text.includes('material'))[0].subtags:[])"
            v-bind:key="index+'-subtag'"
            v-model="materialArray"
            :value="subtag.text">{{subtag.text}}</b-form-checkbox>
        </b-dd-form>
      </b-dropdown> -->

      <br/>
      <div class="pl-1 pr-1">
        <b-badge
          v-for="(whatIsSubtag, index) in whatIsArray"
          :key="'whatIsSubtag-'+index"
          variant="info"
          class="cursor-pointer mr-1"
          @click="whatIsArray.splice(whatIsArray.indexOf(whatIsSubtag), 1)"
        >{{whatIsSubtag}} ×</b-badge>

        <b-badge
          v-for="(diagSubtag, index) in diagArray"
          :key="'diagSubtag-'+index"
          variant="info"
          class="cursor-pointer mr-1"
          @click="diagArray.splice(diagArray.indexOf(diagSubtag), 1)"
        >{{diagSubtag}} ×</b-badge>

        <b-badge
          v-for="(gradeSubtag, index) in gradeArray"
          :key="'gradeSubtag-'+index"
          variant="info"
          class="cursor-pointer mr-1"
          @click="gradeArray.splice(gradeArray.indexOf(gradeSubtag), 1)"
        >{{gradeSubtag}} ×</b-badge>

        <b-badge
          v-for="(professionSubtag, index) in professionArray"
          :key="'professionSubtag-'+index"
          variant="info"
          class="cursor-pointer mr-1"
          @click="professionArray.splice(professionArray.indexOf(professionSubtag), 1)"
        >{{professionSubtag}} ×</b-badge>

        <b-badge
          v-for="(habilitySubtag, index) in habilityArray"
          :key="'habilitySubtag-'+index"
          variant="info"
          class="cursor-pointer mr-1"
          @click="habilityArray.splice(habilityArray.indexOf(habilitySubtag), 1)"
        >{{habilitySubtag}} ×</b-badge>

        <b-badge
          v-for="(materialSubtag, index) in materialArray"
          :key="'materialSubtag-'+index"
          variant="info"
          class="cursor-pointer mr-1"
          @click="materialArray.splice(materialArray.indexOf(materialSubtag), 1)"
        >{{materialSubtag}} ×</b-badge>
      </div>
    </div>

    <div class="posts" :key="posts.length">
      <div
        class="padding-box-normal"
        v-for="(post, index) in posts"
        :key="'post-component-'+index+'-'+post.id"
      >
        <PostComponent
          :countries="countries"
          :post="post"
          :custom_tags="custom_tags"
          v-on:view-file="viewFile"
          v-on:points-change="points_change"
          v-on:reload="get_posts()"
          :show_results="false"
          :self_index="index"
        />
      </div>
      <infinite-loading @infinite="infiniteHandler" ref="InfiniteLoading" v-if="$store.state.login.token || (!$store.state.login.token && !posts.length)"></infinite-loading>
      <b-row v-else>
        <b-col class="text-center">
          Para ver más contenido inicia sesión :)
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import PostComponent from "../miscellaneous/PostComponent";
import RedirectOptions from "../miscellaneous/RedirectOptions";
import postService from "../../../services/postService";
import PublishComponent from "../miscellaneous/PublishComponent";
import Publish from "../imported/Publish";
import TagComponent from "../miscellaneous/TagComponent";
import informationService from "../../../services/informationService";
import userService from '../../../services/userService';
import EurekaTable from '../../redpie/miscellaneous/EurekaTable.vue';

export default {
  components: {
    PostComponent,
    PublishComponent,
    RedirectOptions,
    TagComponent,
    Publish,
    EurekaTable
  },
  computed: {
    token: function() {
      return this.$store.state.login.token;
    },
    searchedSubtags: function() {
      return this.whatIsArray.concat(
          this.diagArray, this.gradeArray, this.professionArray, this.habilityArray, this.materialArray);
    }
  },
  created() {
    const query = window.location.hash.split('#')[1].split('?')[1]
    const params = Object.fromEntries(new URLSearchParams(query))
    if (Object.keys(params).length) {
      if (params.buy_order) {
        userService.addTransactionPoints({buy_order: params.buy_order}).then(
          resp => {
            userService.get_user_data().then((data) => {
              this.$store.commit("login/setPoints", data, { root: true });
            });
            this.$toasted.success(resp.message)
          },
          error => {
            var message = "Ha ocurrido un error al cargar tus puntos.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        )
      }
    }
    if (this.$store.state.login.subtag) {
      this.get_posts_by_subtag(this.$store.state.login.subtag)
      this.$store.commit('login/setSubtag',{ subtag: "" })
    }
    else {
      this.get_posts();
    }
    if (this.$store.state.login.token) {
      this.set_custom_tags();
      this.getCountries();
    }
  },
  data: function() {
    return {
      posts: [],
      view_link: "",
      download_link: "",
      searching_by: { tags: [], subtags: [], text: ""},
      custom_tags: [],
      page: 1,
      mode: 'posts',
      loadingFunc: null,
      countries: [],
      whatIsArray: [],
      diagArray: [],
      gradeArray: [],
      professionArray: [],
      habilityArray: [],
      materialArray: [],
    };
  },
  methods: {
    openEureka() {
      this.$refs["eureka-modal"].show();
    },
    setLoadingFunc(fun) {
      this.loadingFunc = fun;
    },
    getCountries: function() {
      informationService.getCountries().then(
        data => {
          var countries = Object.keys(data.countries).map(function(key){
              return data.countries[key];
          });
          this.countries = countries;
        }
      )
    },
    remove_subtag_by_index: function(subtag_index) {
      this.searching_by.subtags.splice(subtag_index, 1);
      if (this.searching_by.subtags.length > 0) {
        this.get_posts_by_subtag();
      }
      else {
        this.get_posts();
      }
    },
    points_change: function() {
      this.$emit("points-change", true);
    },
    publish_post: function(post_data) {
      this.loadingFunc();
      let formData = new FormData();
      // verify form and add data to the form data
      if (post_data.has_poll) {
        if (!post_data.question) {
          this.$toasted.error("Debes agregar una pregunta a tu encuesta!");
          this.loadingFunc();
          return;
        }
        formData.append("question", post_data.question);
        // add option to the form data
        for (var i = 0; i < post_data.options.length; i++) {
          let option = post_data.options[i].value;
          if (!option) {
            this.$toasted.error(
              "Todas las opciones de tu encuesta deben tener texto asociado!"
            );
            this.loadingFunc();
            return;
          }
          formData.append("options", option);
        }
      }
      if (!post_data.text) {
        this.$toasted.error("Tu publicación debe tener algo escrito!");
        this.loadingFunc();
        return;
      }
      // add files to the form data
      for (var j = 0; j < post_data.files.length; j++) {
        formData.append("files", post_data.files[j]);
      }
      formData.append("text", post_data.text);
      for (var k = 0; k < post_data.subtags.length; k++) {
        formData.append("subtags", post_data.subtags[k].id);
      }
      formData.append("has_poll", post_data.has_poll);
      formData.append("reward", post_data.reward);
      if (this.$store.state.login.token) {
        postService.publish_post(formData).then(
          data => {
            this.$toasted.success(data.message);
            this.get_posts();
            this.$emit("points-change", true);
            this.loadingFunc();
          },
          error => {
            this.$toasted.error("Ha ocurrido un error al publicar");
            console.error(error);
            this.loadingFunc();
          }
        );
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
        this.loadingFunc();
      }
    },
    get_service: function() {
      if (this.mode === 'posts') {
        return postService.get_posts;
      }
      else if (this.mode === 'tags') {
        return postService.get_posts_by_tag;
      }
      else if (this.mode === 'subtags') {
        return postService.get_posts_by_subtag;
      }
      else if (this.mode === 'search') {
        return postService.get_posts_by_search;
      }
      return postService.get_posts;
    },
    get_args: function() {
      if (this.mode === 'posts') {
        return {page: this.page};
      }
      else if (this.mode === 'tags') {
        let tags = [];
        this.searching_by.tags.forEach(
            x => {
              tags.push(x)
            }
        )
        return {tag: tags, page: this.page}
      }
      else if (this.mode === 'subtags') {
        let subtags = [];
        this.searching_by.subtags.forEach(
            x => {
              subtags.push(x)
            }
        )
        return {subtag: subtags, page: this.page}
      }
      else if (this.mode === 'search') {
        return {search: this.searching_by.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), page: this.page};
      }
      return {page: this.page};
    },
    infiniteHandler: function($state) {
      const service = this.get_service();
      const args = this.get_args();
      service(args).then(
        data => {
          if (data.length > 0) {
            this.page += 1;
            this.posts = this.posts.concat(data);
            $state.loaded();
          }
          else {
            $state.complete();
          }
        },
        error => {
          this.$toasted.error(
            "Ha ocurrido un error al obtener las publicaciones"
          );
          console.error(error);
        }
      );
    },
    set_mode_posts: function() {
      this.page = 1;
      this.mode = 'posts';
      this.posts = [];
    },
    set_mode_tags: function(tag) {
      this.searching_by.tags.push(tag);
      this.page = 1;
      this.mode = 'tags';
      this.posts = [];
      this.force_get_posts();
    },
    set_mode_subtags: function(subtag) {
      if (subtag) {
        this.searching_by.subtags = []
        this.searching_by.subtags.push(subtag);
      }
      this.page = 1;
      this.mode = 'subtags';
      this.posts = [];
      this.force_get_posts();
    },
    set_mode_search: function(text) {
      this.searching_by.text = text;
      this.page = 1;
      this.mode = 'search';
      this.posts = [];
      this.force_get_posts();
    },
    get_posts: function() {
      this.set_mode_posts();
    },
    force_get_posts: function() {
      this.$refs.InfiniteLoading.stateChanger.reset();
    },
    get_posts_by_tag: function(tag) {
      this.set_mode_tags(tag);
    },
    get_posts_by_subtag: function(subtag) {
      this.set_mode_subtags(subtag);
    },
    search: function(text) {
      if (text) {
        this.set_mode_search(text);
        this.reset()
        this.force_get_posts()
      } else {
        this.$toasted.error("Escribe un texto para poder buscar!");
      }
    },
    reset: function() {
      this.page = 1;
      this.posts = [];
      this.infiniteID += 1;
    },
    viewFile: function(links) {
      this.$emit("view-file", links);
    },
    set_custom_tags: function() {
      postService.get_tags().then(
        data => {
          for (var i = 0; i < data.tags.length; i++) {
            if (data.tags[i].text !== "No asignado" && data.tags[i].text !== "No etiquetado")
              this.custom_tags.push(data.tags[i]);
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  },
  props: {
    tags: { type: Array, default: () => [] }
  },
  watch: {
    // eslint-disable-next-line
    token(new_value, old_value) {
      this.get_posts();
      this.set_custom_tags();
      this.getCountries();
    },
    // esling-disable-next-line
    searching_by() {
      this.get_posts_by_subtag();
    },
    searchedSubtags() {
      this.searching_by.subtags = this.searchedSubtags;
      this.set_mode_subtags();
    },
  }
};
</script>

<style>
.normal-dropdown .dropdown-menu {
  width: 400px;
  max-height: 150px;
  overflow-y: auto;
}
.filters {
  width: 99% !important;
  background-color: white !important;

}
.bd-sidebar {
  border-right: 1px solid #eee;
}
.bd-toc {
  border-left: 1px solid #eee;
}
.no-hover-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.no-hover-info:hover {
  border-color: #17a2b8;
  color: #17a2b8;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.no-login {
  border-radius: 0.3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(23, 162, 184);
  text-align: center;
}
.no-login-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.border-green {
  border: 1px solid rgba(23, 162, 184, 0.33);
}
</style>
